.fs-14 {
  	font-size: 14px !important;
}

.fs-16 {
  	font-size: 16px !important;
}

.fs-18 {
  	font-size: 18px !important;
}

.fs-25 {
  	font-size: 25px !important;
}

.fs-30 {
  	font-size: 30px !important;
}

.fs-36 {
  	font-size: 36px !important;
}

.fs-40 {
  	font-size: 40px !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}